import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Flex, Link, Spacer, Text } from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";
import React from "react";
import getMemoriamPath from "src/lib/getMemoriamPath";

import Picture from "../Picture";

interface MemberCardProps {
  member: Gatsby.MemberFragment;
}

const MemberCard = ({ member }: MemberCardProps) => {
  const Component = member.isDeceased
    ? GatsbyLink
    : member.website
    ? Link
    : undefined;

  const hasLink = member.website || member.isDeceased;

  return (
    <Flex
      _hover={{
        bgColor: hasLink ? "gray.100" : undefined,
        textDecoration: "none",
      }}
      align="center"
      as={Component}
      bgColor="gray.50"
      border="1px"
      borderColor="gray.200"
      borderRadius="lg"
      {...(member.website ? { href: member.website, target: "_blank" } : {})}
      minH="63px"
      overflow="hidden"
      px={4}
      py={2}
      role="group"
      to={member.isDeceased ? getMemoriamPath(member.id!) : undefined}
      transitionDuration="normal"
      transitionProperty="common"
      transitionTimingFunction="ease-out"
    >
      <Picture
        alignSelf="stretch"
        alt={`Photographie de ${member.firstName} ${member.lastName}`}
        image={member.portrait?.gatsbyImageData}
        ml={-4}
        mr={4}
        my={-2}
      />

      <Box>
        <Text as="span" fontWeight="medium">
          {member.firstName}
        </Text>

        <Text as="span" fontWeight="semibold">
          {` ${member.lastName}`}
        </Text>

        <Text fontWeight="semibold" variant="caption">
          {member.role}
        </Text>
      </Box>

      <Spacer />

      {hasLink && (
        <ChevronRightIcon
          _groupHover={{ transform: "translateX(3px)" }}
          color="gray.500"
          fontSize="1.5em"
          transitionDuration="normal"
          transitionProperty="common"
          transitionTimingFunction="ease-out"
        />
      )}
    </Flex>
  );
};

export default MemberCard;
