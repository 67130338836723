import {
  render,
  StructuredTextGraphQlResponse,
} from "datocms-structured-text-to-plain-text";
import { StructuredTextType } from "src/components/StructuredText/StructuredText";

const getExcerpt = (
  structuredText?: StructuredTextType,
  { length }: { length?: number } = {}
) => {
  const toText = render(
    structuredText as unknown as StructuredTextGraphQlResponse
  )!;

  if (!length) return toText;

  return toText.slice(0, length).concat(length > toText.length ? "..." : "");
};

export default getExcerpt;
