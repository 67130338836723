import {
  Text,
  Container,
  Divider,
  Heading,
  SimpleGrid,
} from "@chakra-ui/react";
import { graphql, PageProps } from "gatsby";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import Card from "src/atoms/Card";
import Layout from "src/components/Layout";
import Markdown from "src/components/Markdown";
import MemberCard from "src/components/MemberCard";
import StructuredText from "src/components/StructuredText";
import { formatDate } from "src/lib/formatDate";
import getExcerpt from "src/lib/getExcerpt";

const AboutUs: FC<PageProps<Gatsby.AboutUsQuery>> = ({
  data: { datoCmsAbout, administrators, founders, regular, deceased },
}) => {
  if (!datoCmsAbout) throw Error();

  return (
    <Layout>
      <Helmet
        meta={[
          {
            name: "description",
            content: getExcerpt(datoCmsAbout.body, { length: 157 }),
          },
        ]}
        title={datoCmsAbout.title}
      />

      <Container maxW="container.xl">
        <Card size="lg">
          <Heading as="h1" mb={8} size="xl">
            {datoCmsAbout.title}
          </Heading>

          <Markdown>
            <StructuredText data={datoCmsAbout.body} />
          </Markdown>

          <Divider my={8} />

          <Heading as="h2" mb={6} size="lg">
            Membres du bureau
          </Heading>

          <SimpleGrid columns={{ base: 1, sm: 2, lg: 3 }} spacing={6}>
            {administrators.nodes.map((member) => (
              <MemberCard
                key={`${member.firstName}-${member.lastName}`}
                member={member}
              />
            ))}
          </SimpleGrid>

          <Divider my={8} />

          <Heading as="h2" mb={6} size="lg">
            Membres fondateurs
          </Heading>

          <SimpleGrid columns={{ base: 1, sm: 2, lg: 3 }} spacing={6}>
            {founders.nodes.map((member) => (
              <MemberCard
                key={`${member.firstName}-${member.lastName}`}
                member={member}
              />
            ))}
          </SimpleGrid>

          <Divider my={8} />

          <Heading as="h2" mb={2} size="lg">
            Sociétaires
          </Heading>

          <Text mb={6}>
            <Text as="span" fontWeight="bold">
              {`${regular.nodes.length} sociétaires `}
            </Text>

            {`à la date du ${formatDate(new Date())}`}
          </Text>

          <SimpleGrid columns={{ base: 1, sm: 2, lg: 3 }} spacing={6}>
            {regular.nodes.map((member) => (
              <MemberCard
                key={`${member.firstName}-${member.lastName}`}
                member={member}
              />
            ))}
          </SimpleGrid>

          <Divider my={8} />

          <Markdown mb={8}>
            <StructuredText data={datoCmsAbout.inMemoriamBody} />
          </Markdown>

          <SimpleGrid columns={{ base: 1, sm: 2, lg: 3 }} spacing={6}>
            {deceased.nodes.map((member) => (
              <MemberCard
                key={`${member.firstName}-${member.lastName}`}
                member={member}
              />
            ))}
          </SimpleGrid>
        </Card>
      </Container>
    </Layout>
  );
};

export default AboutUs;

export const query = graphql`
  query AboutUs {
    datoCmsAbout {
      title
      body {
        value
      }
      inMemoriamBody {
        value
      }
    }

    administrators: allDatoCmsMember(
      filter: { isAdministrator: { eq: true }, isDeceased: { eq: false } }
      sort: { fields: lastName, order: ASC }
    ) {
      nodes {
        ...Member
      }
    }

    founders: allDatoCmsMember(
      filter: { isFounder: { eq: true }, isDeceased: { eq: false } }
      sort: { fields: lastName, order: ASC }
    ) {
      nodes {
        ...Member
      }
    }

    regular: allDatoCmsMember(
      filter: { isDeceased: { eq: false } }
      sort: { fields: lastName, order: ASC }
    ) {
      nodes {
        ...Member
      }
    }

    deceased: allDatoCmsMember(
      filter: { isDeceased: { eq: true } }
      sort: { fields: lastName, order: ASC }
    ) {
      nodes {
        ...Member
      }
    }
  }
`;
