import { Box, BoxProps } from "@chakra-ui/react";
import React, { FC } from "react";

const Markdown: FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box
      sx={{
        ">p": {
          my: 4,
        },

        ">ul": {
          ml: "18px",
          ">li": {
            mb: 1,
          },
        },
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default Markdown;
